///////////////////////////////////////////////////////////////////

export var capitalizeFirstLetter = (stringValue) => { 
	return stringValue[0].toUpperCase() + stringValue.slice(1); 
};

///////////////////////////////////////////////////////////////////

const s4 = function () {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

export var guid = function () {
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
		s4() + '-' + s4() + s4() + s4();
};

///////////////////////////////////////////////////////////////////

export function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function(m, r, g, b) {
		return r + r + g + g + b + b;
	});
  
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}

///////////////////////////////////////////////////////////////////

function componentToHex(c) {
	var hex = c.toString(16);
	return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function getSpacer() {
	return <div className="spacer">&nbsp;</div>;
}

export function getParams() {
	var pairs = window.location.search.substring(1).split("&"),
		obj = {},
		pair,
		i;

	for ( i in pairs ) {
		if ( pairs[i] === "" ) continue;

		pair = pairs[i].split("=");
		obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
	}

	return obj;
}

export function copy(objectItem) {
	return objectItem ? JSON.parse(JSON.stringify(objectItem)) : null;
}