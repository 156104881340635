import React  from 'react';
import CONFIG from '../config';
import $ from 'jquery';
import jQuery from 'jquery';
import "react-datetime/css/react-datetime.css";
import "../index.css";
import * as Utils from '../lib/utils.js';
import { MsalContext } from "@azure/msal-react";

window.jQuery = jQuery;
require("jsgrid");

//SETUP PROD API HOST AS WELL
const API_HOST = CONFIG.API_HOST;

class References extends React.Component{
    static contextType = MsalContext;

    constructor(props) {
        super(props);
        this.gridRef = React.createRef();

        this.state = {
            startDate: "",
            endDate: ""
        }
    }

    sendToReference(item) {
        // console.log("item", item);

        if(window.confirm("Are you sure you'd like to send this reference a request for information about this candidate?")){

            const loggedInEmail = this.context.accounts && this.context.accounts.length ? this.context.accounts[0].username : "";

            return $.ajax({
                method: "POST",
                url: API_HOST + "/request_reference_feedback",
                dataType: "json",
                data: {
                    reference_id : item.id,
                    logged_in_email : loggedInEmail
                }
            }).then((candidateResults)=>{
                if(candidateResults.status === "ERROR" || candidateResults.status === "ALERT"){
                    alert(candidateResults.status + "! " + candidateResults.message)
                }else{
                    alert("Email has been sent to candidate references.");
                }
                this.mainGrid.jsGrid("loadData");
            });    
        }
    }

    componentDidMount() {
        let dc=this;

        const complete = '<i style="color:#090;" class="fas fa-check"></i>';
        const incomplete = '<span style="color:#F00;font-weight:bold;">X</span>';

        this.mainGrid = window.jQuery(this.gridRef.current);

        console.log("MAKING Reference GRID");
        this.mainGrid.jsGrid({
            height: "480px",
            width: "98%",
     
            filtering: false,
            sorting: true,
            paging: true,
            autoload: true,
            editing: false,
            // inserting: true,
     
            deleteConfirm: function(item) {
                return "Are you sure you want to delete this document?"; //update this with data 
            },
     
            controller: {
                loadData: function(item) {
                    return $.ajax({
                        url: API_HOST + "/reference_data",
                        dataType: "json",
                        data: item
                    }).then((referenceResults)=>{
                        console.log("referenceResults", referenceResults)
                        for(var i=0;i<referenceResults.length;i++){
                            referenceResults[i].candidate_full_name = 
                                referenceResults[i].candidate_first_name + 
                                referenceResults[i].candidate_last_name;
                        }
                        return referenceResults;
                    });

                },
    
                deleteItem: function(item) {
                    // console.log("DELETING ITEM: ", item);
                    return $.ajax({
                        type: "DELETE",
                        url: API_HOST + "/reference_data",
                        data: item
                    }).then(function(deleteResult){

                        //leave the logs here

                        return deleteResult;
                    });
                },
            },
     
            editItem: function(item) {
                // console.log("item", item)

                var $row = this.rowByItem(item);

                if ($row.length) {
                    //   console.log('$row: ', JSON.stringify($row), $row); // I modify this

                    dc.editingId = item.id;

                    $('#referenceModalContainer').modal();

                    $('#referenceType').val(item.reference_type || "peer");
                    $('#firstName').val(item.first_name);
                    $('#lastName').val(item.last_name);  
                    $('#companyName').val(item.company_name);
                    $('#phone').val(item.phone);
                    $('#email').val(item.email);
                    $('#details').val(item.details);
                    $('#referencesMinimum').val(item.references_minimum);

                    dc.setState({
                        candidateId : item.candidate_id
                    }, ()=>{
                        this._editRow($row);
                    })
                }
            },

            fields: [
                { type: "control", editButton: true, deleteButton: false },
                { 
                    title: "", name: 'preview', 
                    type: "text", width: "40", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $('<i class="fas fa-eye"></i>').click(function(e){
                            dc.previewReference(item);
                        });
                    }
                },
                { 
                    title: "", name: 'download', 
                    type: "text", width: "40", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $('<i class="fas fa-download"></i>').click(function(e){
                            console.log("item", item)
                            dc.downloadReference(item.id);
                        });
                    }
                },
                { type: "control", editButton: false, deleteButton: true },
                { title: "Id", name: 'id', type: "text", width: 60, css: "tnkGridCell", sortable:true, sorter: "number" },
                { title: "First Name", name: 'first_name', type: "text", width: "auto", css: "tnkGridCell", sortable:true },
                { title: "Last Name", name: 'last_name', type: "text", width: "auto", css: "tnkGridCell", sortable:true },
                { 
                    title: "For Candidate", name: 'candidate_full_name', sortable:true, 
                    type: "text", width: "auto", css: "tnkGridCell"
                },
                { title: "Email", name: 'email', type: "text", width: "auto", css: "tnkGridCell", sortable:true },
                { 
                    title: "Send To Ref", name: 'send_references', 
                    type: "text", width: "auto", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $('<i class="fas fa-envelope"></i><span class="gridLink"> Send</span>').click(function(e){
                            dc.sendToReference(item);
                        });              
                    } 
                },
                { 
                    title: "Request sent?", name: 'reference_is_sent', 
                    type: "text", width: "auto", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $(item.reference_is_sent ? complete : incomplete);
                    }                     
                },                             
                { 
                    title: "Responded?", name: 'reference_responded', 
                    type: "text", width: "auto", css: "tnkGridCell", 
                    itemTemplate: function(value, item) {
                        return $(item.reference_is_done ? complete : incomplete);
                    } 
                },
            ]
    
        }); 

        // // sort clients by name and then by age
        // this.mainGrid.sortStrategies.candidateName = function(index1, index2) {
        //     var candidate1 = clients[index1];
        //     var candidate2 = clients[index2];
        //     return candidate1.candidate_first_name.localeCompare(candidate2.candidate_first_name);
        // };

        $.ajax({
            url: API_HOST + "/candidate_data",
            dataType: "json",
            data: null
        }).then((candidateResults)=>{
            this.setState({
                candidates : candidateResults
            })
            return false;
        });

    }
    
    previewReference(item) {

        let referenceDetailsString = "<strong>Candidate</strong>: " + item.candidate_first_name + " " + item.candidate_last_name + "<br />" + 
            "<strong>Reference type</strong>: " + (item.reference_type || "peer") + "<br />" +
            "<strong>Date completed</strong>: " + (item.reference_done_datetime ? (new Date(item.reference_done_datetime)).toLocaleString() : "Not yet complete") + "<br />" +
            "<strong>First name</strong>: " + item.first_name + "<br />" +
            "<strong>Last name</strong>: " + item.last_name + "<br />" +
            "<strong>Company name</strong>: " + (item.company_name || "Not set") + "<br />" +
            "<strong>Phone</strong>: " + (item.phone || "Not Set") + "<br />" +
            "<strong>Email</strong>: " + item.email + "<br /><br />" +
            "<strong>Reference Responses:</strong> <br />";

        // console.log("item", item);

        if(item.responses_json && item.responses_json.length){
            for(let i=0;i<item.responses_json.length;i++){
                referenceDetailsString = referenceDetailsString + "<strong>" + item.responses_json[i][0] + "</strong> <br />" + item.responses_json[i][1] + "<br /><br />";
            }
        }else{
            referenceDetailsString = referenceDetailsString + "<br />This reference has not yet completed their feedback.<br /><br />";
        }

        $("#detailsModalBody").html(referenceDetailsString);
        $('#detailsModalContainer').modal()

        return false;
    }

    downloadReference(referenceId) {

        window.location = API_HOST +"/download_reference/" + referenceId;
        return false;

    }

    showAddReference() {
        this.editingId = null;

        this.clearModalFields();

        $('#referenceModalContainer').modal()
    }

    referenceIsValid(referenceDetails) {
        let isValid = true;
        let message = "";
        let requiresValueFields = ["first_name", "last_name", "phone", "email"];
        for(var key in referenceDetails){
          if(requiresValueFields.indexOf(key) > -1 && !referenceDetails[key]){
            isValid = false;
            message += "Please ensure all fields are filled out.\r\n";
            break;
          }
        }
        return {
          resultIsValid : isValid,
          message : message
        };
    }

    saveReference() {
        const dc = this;

        const referenceDetails = {
            candidate_id: $('#jobCandidate').val(),
            reference_type: $('#referenceType').val(),
            first_name: $('#firstName').val(),
            last_name: $('#lastName').val(),  
            // address: $('#address').val(),
            // city: $('#city').val(),
            // state: $('#state').val(),
            // zip: $('#zip').val(),
            phone: $('#phone').val(),
            email: $('#email').val(),
            details: $('#details').val()
        };

        if(this.editingId){
            referenceDetails.id = this.editingId;
        }

        // console.log("referenceDetails", referenceDetails);

        const isValidResult = this.referenceIsValid(referenceDetails);
        if(isValidResult.resultIsValid){
            return $.ajax({
                type: (referenceDetails.id ? "PUT" : "POST"),
                url: API_HOST + "/reference_data",
                data: referenceDetails,
                success: function(model, result, xhr){
                    $('#referenceModalContainer').modal('hide');

                    dc.clearModalFields();
                    
                    dc.mainGrid.jsGrid("loadData");
                },
                error: function(result, xhr){
                    $('#logModalContainer').modal('hide');
                    alert("There was an error entering the data.  If tihs issue continues, please contact the site administror at softwareengineeringsupport@geoengineers.com.")
                    dc.mainGrid.jsGrid("loadData");
                }
            });
        }else{
            console.log("isValidResult", isValidResult);
            alert(isValidResult.message);
        }

        return false;
    }

    clearModalFields() {
        $('#jobCandidate').val("");
        $('#referenceType').val("");
        $('#firstName').val("");
        $('#lastName').val("");  
        // $('#address').val("");
        // $('#city').val("");
        // $('#state').val("");
        // $('#zip').val("");
        $('#companyName').val("");
        $('#phone').val("");
        $('#email').val("");
        $('#details').val("");

        this.editingId = null;
        this.setState({
            candidateId : 0
        })
        return false;
    }

    handleCandidateChange(event) {
        this.setState({
            candidateId : event.target.value
        });
    }

    getCandidatesOptions() {
        return <select name="jobCandidate" id="jobCandidate" style={{ width:"250px" }} value={this.state.candidateId} onChange={(event)=>{
            this.handleCandidateChange(event);
        }}>
            {this.state.candidates && this.state.candidates.length ? this.state.candidates.map((candidateItem)=>{
                return <option key={Utils.guid()} value={candidateItem.id}>
                    {candidateItem.first_name} {candidateItem.last_name}
                </option>
            }) : false}
        </select>
    }

    render() {
        // console.log("this.state", this.state)

        return <div className="grid-default-dimensions">
            <div id="reference-container" className="container-fluid" ref={this.gridRef}></div>

            <div className="container-fluid" style={{ textAlign:"right" }}>
                <button type="button" className="btn btn-primary" data-toggle="modal" onClick={this.showAddReference.bind(this)}> {/*  data-target="#referenceModalContainer" */}
                    Add candidate job reference
                </button>
            </div>

            <canvas id="canvas"></canvas>

            <div className="modal" tabIndex="-1" id="detailsModalContainer"  role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body" id="detailsModalBody">
                            No content.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="cancelCandidate">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" tabIndex="-1" id="referenceModalContainer"  role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Enter Reference Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <div className="row">
                                <div className="col-4">Job Candidate:</div>
                                <div className="col-8">
                                    {this.getCandidatesOptions()}
                                </div>
                            </div>                            
                            <div className="row">
                                <div className="col-4">Reference Type:</div>
                                <div className="col-8">
                                    <select name="referenceType" id="referenceType" style={{ width:"250px" }}>
                                        <option value="academic">Academic</option>
                                        <option value="supervisor">Supervisor</option>
                                        <option value="peer">Peer</option>
                                        <option value="client">Client</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">First Name:</div>
                                <div className="col-8"><input type="text" name="firstName" id="firstName" style={{ width:"250px" }} /></div>
                            </div>
                            <div className="row">
                                <div className="col-4">Last Name:</div>
                                <div className="col-8"><input type="text" name="lastName" id="lastName" style={{ width:"250px" }} /></div>
                            </div>

                            <div className="row">
                                <div className="col-4">Company Name:</div>
                                <div className="col-8"><input type="text" name="companyName" id="companyName" style={{ width:"250px" }} /></div>
                            </div>

                            <div className="row">
                                <div className="col-4">Phone:</div>
                                <div className="col-8"><input type="text" name="phone" id="phone" style={{ width:"250px" }} /></div>
                            </div>
                            <div className="row">
                                <div className="col-4">Email:</div>
                                <div className="col-8"><input type="text" name="email" id="email" style={{ width:"250px" }} /></div>
                            </div>

                            {/* <div className="row">
                                <div className="col-4">Address:</div>
                                <div className="col-8"><input type="text" name="address" id="address" style={{ width:"250px" }} /></div>
                            </div>
                            <div className="row">
                                <div className="col-4">City:</div>
                                <div className="col-8"><input type="text" name="city" id="city" style={{ width:"250px" }} /></div>
                            </div>
                            <div className="row">
                                <div className="col-4">State:</div>
                                <div className="col-8"><input type="text" name="state" id="state" style={{ width:"250px" }} /></div>
                            </div>

                            <div className="row">
                                <div className="col-4">Zip:</div>
                                <div className="col-8"><input type="text" name="zip" id="zip" style={{ width:"250px" }} /></div>
                            </div> */}

                            <div className="row">
                                <div className="col-4">Additional Details:</div>
                                <div className="col-8"><input type="text" name="details" id="details" style={{ width:"250px" }} /></div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" id="saveReference" onClick={this.saveReference.bind(this)}>Save changes</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="cancelReference">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}  

export default References;