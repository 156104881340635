import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from '@mui/material/Button';

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutMenuItem = () => {
    const { instance } = useMsal();

    return (
        <li><a className="dropdown-item" href="/" onClick={(event)=>{
            event.preventDefault();
            handleLogout(instance);
            return false;
        }}>Log out</a></li>
    );
}