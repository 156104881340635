import { Component } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { msalConfig } from "../config/authConfig";

// import Home from "../routes/home";
import Candidates from "../routes/candidate";
import CandidateReferences from "../routes/candidateReferences";
import References from "../routes/reference";
import ReferenceForm from "../routes/referenceForm";

import { SignOutMenuItem } from "../components/signOutMenuItem";

import LogoImg from '../images/geo_logo.dcfac9cb-compressed.svg';

const pca = new PublicClientApplication(msalConfig);

class RouterScreen extends Component{
  constructor(props){
    super(props);

    // console.log("BASE STATE", props, this)

    this.state = { 
      apiResponse: "",
      isOpen: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggleDropdownOpen = () => this.setState({ isOpen: !this.state.isOpen });
  
  handleOpenModal = () => {
    this.setState({ showModal: true });
  }
  
  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  componentDidMount() {}

  getNavMenu() {
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
    
    return <div id="menu" className="dropdown col-sm-6" style={{ textAlign:"right" }} onClick={this.toggleDropdownOpen}>
      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ margin:"10px 20px 0px", left: "auto", right: "40px"}}>
        Menu
      </button>
      <ul id="menu-dropdown" className={menuClass} aria-labelledby="dropdownMenuButton1" style={{ left:"auto", right:"20px" }}>
        <li><span style={{"marginLeft":"25px", borderBottom:"1px solid #DDD"}}>v0.3.2</span></li>
        {/* <li><a className="dropdown-item" href="/">Home</a></li> */}
        <li><a className="dropdown-item" href="/candidate">Candidates</a></li>
        {/* <li><a className="dropdown-item" href="/candidateReferencesForm">Candidate References Input Form</a></li>  */}
        <li><a className="dropdown-item" href="/reference">References</a></li>
        {/* <li><a className="dropdown-item" href="/referenceForm">Reference Input Form</a></li> */}
        <SignOutMenuItem />
      </ul>
    </div>;

  }

  isAuthenticated () {
    return true;
  }

  render() {

    return (
      <Router>
        <div style={{
          backgroundColor:"#333", 
          borderBottom:"0.5px solid #DDD", 
          color: "#FFF",
          width:"100%", 
          position:"absolute",
          top:"0px",
          left:"0px",
          margin:"0px",
          minHeight:"60px"
        }}>
          <div className="row main-header" style={{ width: "100%", margin: "0", position:"absolute" }}>
            <div 
              id='geo-logo' 
              className="col-sm-6" 
              style={{
                cursor: "pointer"
              }}
              onClick={()=>{
                window.location = "/";
              }}
            >
              <img src={LogoImg} style={{width: "150px", margin: "0px 0px 5px 0px"}} alt={"GeoEngineers Logo"} />
              &nbsp;<span style={{fontSize: "14pt"}}>Reference Tool</span>
            </div>

            <MsalAuthenticationTemplate>
              {this.getNavMenu()}
            </MsalAuthenticationTemplate>

          </div>
        </div>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <div style={{
          position:"absolute",
          top:"70px",
          width: "100%"
        }}>
          <Routes>
            <Route exact path="/candidate" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <Candidates/>
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>
              </div>
            } />
            <Route exact path="/reference" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <References/>
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
            } />
            <Route exact path="/candidateReferencesForm" element={<CandidateReferences/>} />
            <Route exact path="/referenceForm" element={<ReferenceForm/>} />
            <Route path="*" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <Candidates/>
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>
              </div>
            } />
          </Routes>
        </div> 

        <div id="footer" className="container-fluid">GeoEngineers 2021</div>
      </Router>
    );
  }
}

export default RouterScreen;
