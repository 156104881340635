const baseUrl = "https://ref.geoengineers.com";
const isDev = window.location.href.indexOf(baseUrl) > -1 ? false : true;
// const isDev = false;

let config = {};
const API_HOST = isDev ? "http://localhost:8080" : baseUrl;

const CONFIG = {
	debug : isDev,
	disableYellowBox : true,
	reduxLoggerEnabled : false,	
    API_HOST : API_HOST,
};

export default CONFIG;