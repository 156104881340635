import $ from 'jquery';
import jQuery from 'jquery';
import CONFIG from '../config';
import React  from 'react';
import "../index.css";
import * as Utils from '../lib/utils';

require('bootstrap-toggle');

window.jQuery = jQuery;
require("jsgrid");

const API_HOST = CONFIG.API_HOST;
const defaultReferencesMinimum = 3;

class CandidateReferencesForm extends React.Component{
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();

        this.numberReference = [
            [0,"zero"], [1,"one"], [2,"two"], 
            [3,"three"], [4,"four"], [5,"five"],
            [6,"six"], [7,"seven"], [8,"eight"], 
            [9,"nine"], [10,"ten"]
        ]

        this.state = {
            sessionId : Utils.guid(),
            candidate : null,
            references : [],
            otherRelationClass : "hidden"
        };
    }

    componentDidMount() {

        const params = Utils.getParams();
        
        $.ajax({
            url: API_HOST + "/candidate_data?token=" + params.candidate_token,
            dataType: "json",
            data: null
        }).then((candidateResult)=>{

            if(candidateResult.total_references_added < candidateResult.references_minimum){
                $.ajax({
                    url: API_HOST + "/reference_data?candidate_token=" + params.candidate_token,
                    dataType: "json",
                    data: null
                }).then((referencesResults)=>{
    
                    if(referencesResults.length){ referencesResults[0]._isOpen = true };
    
                    const referencesLength = this.getRequiredReferencesCount(candidateResult, referencesResults);
                    
                    if(referencesResults.length < referencesLength){
                        for(var i=0;i<referencesLength;i++){
                            if(!referencesResults[i]){
                                referencesResults.push({});
                                referencesResults[i]._guid = Utils.guid();
                            }
                        }    
                    }
    
                    this.setState({
                        candidate : candidateResult,
                        references : referencesResults
                    })
    
                });
    
            }else{

                

            }

        });

    }

    isValidReference(reference){
        const keys = Object.keys(reference);
        return keys.length > 1
            && reference.first_name
            && reference.last_name
            && reference.email;
    }

    validateReferences() {
        let validReferences = 0;
        for(var i=0;i<this.state.references.length;i++){
            // console.log("this.state.references[i]", this.state.references[i]);
            if(
                this.isValidReference(this.state.references[i])
            ){
                validReferences++;
            }
        }
        // console.log("validReferences", validReferences);

        if(validReferences < this.state.candidate.references_minimum){
            return false;
        }else{
            return true;
        }
    }

    saveReferences() {
        if(this.validateReferences()){

            this.saveReferenceItem(0)

        }else{

            window.alert("You have not filled out all references.  Please complete all references to submit results."); 

        }
    }

    saveReferenceItem(saveIterator) {
        let saveUrl = API_HOST + "/reference_data";
        let method = "post";
        
        let referenceToSave = {
            "candidate_id": this.state.candidate.id, 
            "reference_type": this.state.references[saveIterator].reference_type,
            "first_name": this.state.references[saveIterator].first_name, 
            "last_name": this.state.references[saveIterator].last_name, 
            "company_name": this.state.references[saveIterator].company_name, 
            "phone": this.state.references[saveIterator].phone, 
            "email": this.state.references[saveIterator].email, 
            "details": this.state.references[saveIterator].details,
        }

        if(this.state.references[saveIterator].id){
            referenceToSave["id"] = this.state.references[saveIterator].id;
            method = "put";
        }
        
        $.ajax({
            method: method,
            url: saveUrl,
            dataType: "json",
            data: referenceToSave
        }).then(()=>{

            if(this.state.references.length > saveIterator+1){
                this.saveReferenceItem(saveIterator+1);
            }else{
                alert("Save complete");
                $("#referenceInputForm").html("Your references have been submitted.");
            }
    
        });

        return false;
    }

    setReferenceItemState(updateInfo) {
        // console.log("update Info", updateInfo)
        let newReferences = this.state.references ? Utils.copy(this.state.references) : [];
        let referenceExists = false;

        // console.log("this.state.references", this.state.references)

        for(var i=0;i<newReferences.length;i++){
            if(newReferences[i].id === updateInfo.referenceId || newReferences[i]._guid === updateInfo._guid){
                newReferences[i][updateInfo.field] = updateInfo.value;
                referenceExists=true;
            }
        }
        
        //if the reference doesn't exist, create it here
        if(!referenceExists){
            newReferences.push({
                _guid : updateInfo._guid
            });
            newReferences[newReferences.length-1][updateInfo.field] = updateInfo.value;
        }

        this.setState({
            references : newReferences
        });
    }

    getReferenceForm (referenceItem, referenceIterator, isOpen) {
        // console.log("Reference Form: ",referenceItem, referenceIterator, isOpen)
        const complete = <i style={{ color : "#090" }} className="fas fa-check"></i>;
        const incomplete = <span style={{ color:"#F00", fontWeight:"bold" }}>X</span>;
        let isComplete = this.isValidReference(referenceItem);

        const refGuid = this.state.sessionId + "-" + referenceIterator;
        return (<div className="card" key={this.state.sessionId + "-" + referenceIterator} id={this.state.sessionId + "-" + referenceIterator}>
            <div className="card-header" id={"heading"+Utils.capitalizeFirstLetter(this.numberReference[referenceIterator][1])}>
                <h2 className="mb-0">
                    <button 
                        className={"btn btn-link" + (isOpen===true?"":" collapsed")} 
                        type="button" 
                        data-toggle="collapse" 
                        data-target={"#collapse" + Utils.capitalizeFirstLetter(this.numberReference[referenceIterator][1])} 
                        aria-expanded={isOpen===true?"false":"true"} 
                        aria-controls={"collapse"+Utils.capitalizeFirstLetter(this.numberReference[referenceIterator][1])}
                        onClick={()=>{
                            //do this in addition to the toggle
                            //let the state know this reference is now defaulted to open
                            // console.log("HAHHA");
                            // let newReferences = Utils.copy(this.state.references);
                            // for(var i=0;i<newReferences.length;i++){
                            //     if(
                            //         (newReferences[i].id && newReferences[i].id === referenceItem.id) || 
                            //         (newReferences[i]._guid && newReferences[i]._guid === referenceItem._guid) 
                            //     ){
                            //         newReferences[i]._isOpen = true;
                            //     }else{
                            //         newReferences[i]._isOpen = false;
                            //     }
                            // }
                            // this.setState({
                            //     references : newReferences
                            // })
                            return true;
                        }}
                    >
                        Reference #{referenceIterator}
                    </button>

                    <span style={{ fontSize:"14pt" }}>
                        <button className="deleteButton" onClick={(event)=>{
                            event.preventDefault();

                            const inputBoxes = $("#"+this.state.sessionId + "-" + referenceIterator + " input");
                            const selectBoxes = $("#"+this.state.sessionId + "-" + referenceIterator + " select");
                            const textareaBoxes = $("#"+this.state.sessionId + "-" + referenceIterator + " textarea");

                            // console.log("selectBoxes", selectBoxes);
                            // console.log("textareaBoxes", textareaBoxes);

                            //cheap way to clear the boxes
                            for(let i=0;i<inputBoxes.length;i++){
                                // console.log("inputBoxes", i, inputBoxes[i]);
                                $(inputBoxes[i]).val("");
                            }
                            $(selectBoxes[0]).val("peer");
                            $(textareaBoxes[0]).val("");

                            // //remove the reference from the system
                            // const newReferences = Utils.copy(this.state.references);
                            // if(referenceItem.id){
                            //     //remove the reference from the DB
                            //     $.ajax({
                            //         method: "delete",
                            //         url: API_HOST + "/reference_data",
                            //         dataType: "json",
                            //         data: {
                            //             id: referenceItem.id
                            //         }
                            //     }).then(()=>{
                                    
                            //         //remove the reference from the state using id 
                            //         for(var i=0;i<newReferences.length;i++){
                            //             console.log("COMPARING", newReferences[i].id, referenceItem.id);
                            //             if(newReferences[i].id === referenceItem.id){
                            //                 newReferences.splice(i, 1);
                            //                 break;
                            //             }
                            //         }

                            //         console.log("newReferences", newReferences);

                            //         this.setState({
                            //             references : newReferences,
                            //             sessionId : Utils.guid()
                            //         }, ()=>{
                            //             alert("Reference deleted.");
                            //         });

                            //     }).catch((error)=>{
                            //         console.log("error", error);
                            //     });    
                            // }else{
                            //     //remove the reference from the state using guid
                            //     for(var i=0;i<newReferences.length;i++){
                            //         if(newReferences._guid === referenceItem._guid){
                            //             newReferences.splice(i, 1);
                            //             break;
                            //         }
                            //     }
                            //     this.setState({
                            //         references : newReferences,
                            //         sessionId : Utils.guid()
                            //     }, ()=>{
                            //         alert("Reference deleted.");
                            //     });

                            // }

                            return false;
                        }}>
                            Clear
                        </button>
                    </span>
                    <span style={{ fontSize:"12pt" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Complete? {isComplete ? complete : incomplete}
                    </span>
                </h2>
            </div>

            <div id={"collapse"+Utils.capitalizeFirstLetter(this.numberReference[referenceIterator][1])} className={"collapse"+(isOpen===true?" show":"")} aria-labelledby={"heading"+Utils.capitalizeFirstLetter(this.numberReference[referenceIterator][1])} data-parent="#accordionExample">
                <div className="card-body">

                    <div className="referenceField">
                        <div>Job reference relationship:</div>
                        <div>
                
                            <select value={referenceItem && referenceItem.reference_type ? referenceItem.reference_type : "peer" } name="relationshipType" id="relationshipType" className='col-sm-6' onChange={(event)=>{
                                const selectedValue = $('#relationshipType').find(":selected").val();
                                // console.log("event.target.value", event.target.value);
                                this.setReferenceItemState({
                                    referenceId : referenceItem.id ? referenceItem.id : -1,
                                    field : "reference_type",
                                    value : event.target.value,
                                    _guid : referenceItem._guid
                                })                                
                            }}>
                                <option value="academic">Academic</option>
                                <option value="peer">Peer</option>
                                <option value="supervisor">Supervisor</option>
                                <option value="client">Client</option>
                            </select>
                        
                        </div>
                    </div>

                    <div className="referenceField">
                        <div>First Name:</div>
                        <div>
                            <input 
                                key={"firstName-"+refGuid}
                                type="text" name={"firstName"+refGuid} id={"firstName-"+refGuid} className='col-sm-6' 
                                value={referenceItem.first_name || ""} onChange={(event)=>{
                                    this.setReferenceItemState({
                                        referenceId : referenceItem.id ? referenceItem.id : -1,
                                        field : "first_name",
                                        value : event.target.value,
                                        _guid : referenceItem._guid
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="referenceField">
                        <div>Last Name:</div>
                        <div>
                            <input 
                                key={"lastName-"+refGuid}
                                type="text" name="lastName" id="lastName" className='col-sm-6' 
                                value={referenceItem.last_name || ""} onChange={(event)=>{
                                    this.setReferenceItemState({
                                        referenceId : referenceItem.id ? referenceItem.id : -1,
                                        field : "last_name",
                                        value : event.target.value,
                                        _guid : referenceItem._guid
                                    })
                                }}
                        />
                        </div>
                    </div>
                    
                    <div className="referenceField">
                        <div>Phone:</div>
                        <div>
                            <input 
                                key={"phone-"+refGuid}
                                type="text" name="phone" id="phone" className='col-sm-6' 
                                value={referenceItem.phone || ""} onChange={(event)=>{
                                    this.setReferenceItemState({
                                        referenceId : referenceItem.id ? referenceItem.id : -1,
                                        field : "phone",
                                        value : event.target.value,
                                        _guid : referenceItem._guid
                                    })
                                }}
                        />
                        </div>
                    </div>
                    
                    <div className="referenceField">
                        <div>Email:</div>
                        <div>
                            <input 
                                key={"email-"+refGuid}
                                type="text" name="email" id="email" className='col-sm-6'
                                value={referenceItem.email || ""} onChange={(event)=>{
                                    this.setReferenceItemState({
                                        referenceId : referenceItem.id ? referenceItem.id : -1,
                                        field : "email",
                                        value : event.target.value,
                                        _guid : referenceItem._guid
                                    })
                                }}
                        />
                     </div>
                    </div>

                    <div className="referenceField">
                        <div>Tell us about your experience working with this individual:</div>
                        <div>
                            <textarea 
                                key={"details-"+refGuid}
                                type="text" name="details" id="details" className='col-sm-6' style={{ minHeight: 250 }} 
                                value={referenceItem.details || ""} onChange={(event)=>{
                                    this.setReferenceItemState({
                                        referenceId : referenceItem.id ? referenceItem.id : -1,
                                        field : "details",
                                        value : event.target.value,
                                        _guid : referenceItem._guid
                                    })
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>)
    }

    getRequiredReferencesCount(candidate, references) {
        // console.log("this.state.candidate", this.state.candidate);
        let candidateReferencesMinimum = candidate ? candidate.references_minimum : defaultReferencesMinimum;
        let baseReferenceCount = this.state.references.length > candidateReferencesMinimum 
            ? references.length
            : candidateReferencesMinimum;

        // console.log("references", references);
        // console.log("candidate", candidate);

        // console.log("baseReferenceCount", baseReferenceCount)
        if(!baseReferenceCount){ baseReferenceCount = defaultReferencesMinimum };

        // console.log("candidateReferencesMinimum", candidateReferencesMinimum)

        return baseReferenceCount;
    }

    renderReferences() {
        let rows = [];
                
        //get either the max number of references, compare it to the minimum, use whichever has more 
        //(this sets us up to allow for adding more than the min)
        const referenceFormItemsCount = this.getRequiredReferencesCount(this.state.candidate, this.state.references);

        // console.log("REFERENCE FORM ITEM COUNT", referenceFormItemsCount);

        for(let i=0;i<referenceFormItemsCount;i++){
            rows.push(this.getReferenceForm(
                (this.state.references[i] ? this.state.references[i] : {}),
                i+1, 
                (
                    (i === 0 && this.state.references.length === 0) 
                    || (this.state.references[i] && this.state.references[i]._isOpen) ? 
                        true : 
                        false
                )
            ));
            // console.log("ROW", rows[i], i, this.state.references[i])
        }

        return rows;
    }

    render() {
        // console.log("STATE", this.state)

        // const referenceFormItemsCount = this.getRequiredReferencesCount(this.state.candidate, this.state.references);
        // const minReferenceCount = this.state.candidate ? this.state.candidate.references_minimum : defaultReferencesMinimum;
        // const maxReferenceCount = 6;

        return this.state.candidate ? (<div id="referenceInputForm" className="grid-default-dimensions">

            <div>
                <div className="accordion" id="accordionExample" key={this.state.sessionId}>
                    {this.renderReferences()}
                </div>
            </div>

            {/* {
                referenceFormItemsCount < maxReferenceCount ? 
                (
                    <div id="addAnotherReferenceBlock" className="col-sm-6" style={{ textAlign: "right" }}>
                        <a href="#" onClick={(event)=>{
                            event.preventDefault();

                            let newReferences = Utils.copy(this.state.references);
                            newReferences.push({
                                _guid : Utils.guid()
                            })

                            //add an extra reference
                            this.setState({
                                references : newReferences
                            });

                            return false;
                        }}>Add another</a>
                    </div>
                ) : (null)
            } */}


            <div className="referenceField">
                <button name="saveReferences" value="saveReferences" onClick={()=>{
                    this.saveReferences();
                }}>Save references</button>
            </div>
        </div>) : (<div>No candidate found or references already filled out.</div>);
    }
}  

export default CandidateReferencesForm;