import React  from 'react';

import CONFIG from '../config';
import $ from 'jquery';
import jQuery from 'jquery';
import "react-datetime/css/react-datetime.css";
import "../index.css";

import { MsalContext } from "@azure/msal-react";

window.jQuery = jQuery;
require("jsgrid");

//SETUP PROD API HOST AS WELL
const API_HOST = CONFIG.API_HOST;

class Candidates extends React.Component{
    static contextType = MsalContext;

    constructor(props) {
        super(props);
        this.gridRef = React.createRef();

        this.state = {
            startDate: "",
            endDate: ""
        }
    }

    previewCandidate(item) {

        const candidateDetailsString = "<strong>First name</strong>: " + item.first_name + "<br />" +
            "<strong>Last name</strong>: " + item.first_name + "<br />" +
            "<strong>Position applied</strong>: " + item.position + "<br />" +
            // "<strong>Phone</strong>: " + item.phone + "<br />" +
            "<strong>Email</strong>: " + item.email + "<br />" +
            "<strong>Additional info</strong>: " + item.details + "<br />" +
            "<strong>Minimum references requested</strong>: " + item.references_minimum;

        // alert(candidateDetailsString);

        console.log("candidateDetailsString", candidateDetailsString);

        $("#detailsModalBody").html(candidateDetailsString);
        $('#detailsModalContainer').modal()

        return false;
    }

    requestCandidateReferences(item) {
        if(window.confirm("Are you sure you'd like to request references from this candidate?")){

            const loggedInEmail = this.context.accounts && this.context.accounts.length ? this.context.accounts[0].username : "";

            return $.ajax({
                method: "post",
                url: API_HOST + "/request_references",
                dataType: "json",
                data: {
                    candidate_id : item.id,
                    references_is_requested : true,
                    references_requested_datetime : (new Date()).toISOString(),
                    logged_in_email : loggedInEmail
                }
            }).then((candidateResults)=>{
                this.mainGrid.jsGrid("loadData");
                alert("Email has been sent to candidate requesting references.");
            });    
        }
    }

    sendToReferences(item) {
        if(window.confirm("Are you sure you'd like to send references a request for information about this candidate? (References who have already recieved requests will not recieve another, use the \"references\" screen to resend a request to a user who has already gotten one.)")){

            const loggedInEmail = this.context.accounts && this.context.accounts.length ? this.context.accounts[0].username : "";

            return $.ajax({
                method: "POST",
                url: API_HOST + "/request_reference_feedback",
                dataType: "json",
                data: {
                    candidate_id : item.id,
                    logged_in_email : loggedInEmail
                }
            }).then((candidateResults)=>{
                if(candidateResults.status === "ERROR" || candidateResults.status === "ALERT"){
                    alert(candidateResults.status + "! " + candidateResults.message)
                }else{
                    alert("Email has been sent to candidate references.");
                }
                this.mainGrid.jsGrid("loadData");
            });    
        }
    }

    componentDidMount() {
        let dc=this;

        const complete = '<i style="color:#090;" class="fas fa-check"></i>';
        const incomplete = '<span style="color:#F00;font-weight:bold;">X</span>';

        this.mainGrid = window.jQuery(this.gridRef.current);
        // console.log("MAKING Candidate GRID");
        this.mainGrid.jsGrid({
            height: "480px",
            width: "98%",
     
            filtering: false,
            sorting: true,
            paging: true,
            autoload: true,
            editing: false,
            // inserting: true,
     
            deleteConfirm: function(item) {
                return "Are you sure you want to delete this document?"; //update this with data 
            },
     
            controller: {
                loadData: function(item) {
                    return $.ajax({
                        url: API_HOST + "/candidate_data",
                        dataType: "json",
                        data: item
                    }).then((candidateResults)=>{
                        return candidateResults;
                    });
                },
    
                deleteItem: function(item) {
                    // console.log("DELETING ITEM: ", item);
                    return $.ajax({
                        type: "DELETE",
                        url: API_HOST + "/candidate_data",
                        data: item
                    }).then(function(deleteResult){
                        return deleteResult;
                    });
                },
            },
     
            editItem: function(item) {
                // console.log("item", item)

                var $row = this.rowByItem(item);

                if ($row.length) {
                    //   console.log('$row: ', JSON.stringify($row), $row); // I modify this

                    dc.editingId = item.id;
                    $('#candidateModalContainer').modal()

                    $('#referenceType').val(item.reference_type);
                    $('#firstName').val(item.first_name);
                    $('#lastName').val(item.last_name);  
                    $('#position').val(item.position);  
                    // $('#address').val(item.address);
                    // $('#city').val(item.city);
                    // $('#state').val(item.state);
                    // $('#zip').val(item.zip);
                    // $('#phone').val(item.phone);
                    $('#email').val(item.email);
                    $('#details').val(item.details);
                    $('#referencesMinimum').val(item.references_minimum);

                    this._editRow($row);
                }
            },

            fields: [
                { type: "control", editButton: true, deleteButton: false },
                { 
                    title: "", name: 'preview', 
                    type: "text", width: "40", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $('<i class="fas fa-eye"></i>').click(function(e){
                            dc.previewCandidate(item);
                        });
                    }
                },
                { type: "control", editButton: false, deleteButton: true },
                { title: "First Name", name: 'first_name', type: "text", width: "auto", css: "tnkGridCell", sortable:true },
                { title: "Last Name", name: 'last_name', type: "text", width: "auto", css: "tnkGridCell" },
                { title: "Date Created", name: 'date_created', type: "text", width: "auto", css: "tnkGridCell" },
                { 
                    title: "Request Refs", name: 'request_refs', 
                    type: "text", width: "auto", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $('<i class="fas fa-envelope"></i><span class="gridLink"> Request</span>').click(function(e){
                            dc.requestCandidateReferences(item);
                        });
                    } 
                },
                { 
                    title: "Refs Requested?", name: 'references_requested', 
                    type: "text", width: "auto", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $(item.references_is_requested ? complete : incomplete);
                    } 
                },
                    
                { 
                    title: "Min Refs Added?", name: 'references_sent', 
                    type: "text", width: "auto", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $(item.total_references_added >= item.references_minimum ? complete : incomplete);
                    } 
                },
                { 
                    title: "Send To Refs", name: 'send_references', 
                    type: "text", width: "auto", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $('<i class="fas fa-envelope"></i><span class="gridLink"> Send</span>').click(function(e){
                            dc.sendToReferences(item);
                        });              
                    } 
                },
                { 
                    title: "Refs Sent?", name: 'references_sent', 
                    type: "text", width: "auto", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $(item.references_is_sent ? complete : incomplete);
                    } 
                },
                { 
                    title: "All Refs Responded?", name: 'references_sent', 
                    type: "text", width: "auto", css: "tnkGridCell",
                    itemTemplate: function(value, item) {
                        return $(
                            item.total_references_responded > 0 
                            && item.total_references_responded >= item.total_references_added 
                                ? complete : incomplete
                        );
                    } 
                }
            ]
    
        });        
    }

    showAddCandidate() {
        this.editingId = null;
        this.clearModalFields();
        $('#candidateModalContainer').modal()
    }

    candidateIsValid(candidateDetails) {
        let isValid = true;
        let message = "";
        let requiresValueFields = ["first_name", "last_name", "email", "references_minimum"];
        for(var key in candidateDetails){
          if(requiresValueFields.indexOf(key) > -1 && !candidateDetails[key]){
            isValid = false;
            message += "Please ensure at least the first name, last name, phone number, email address, and a minimum number of references are filled out.\r\n";
            break;
          }
        }
        return {
          resultIsValid : isValid,
          message : message
        };
    }

    saveCandidate() {
        const dc = this;

        const candidateDetails = {
            first_name: $('#firstName').val(),
            last_name: $('#lastName').val(),  
            position: $('#position').val(),  
            // address: $('#address').val(),
            // city: $('#city').val(),
            // state: $('#state').val(),
            // zip: $('#zip').val(),
            // phone: $('#phone').val(),
            email: $('#email').val(),
            details: $('#details').val(),
            date_created: (new Date()).toLocaleString(),
            references_minimum: $('#referencesMinimum').val(),
            position: $('#position').val()
        };

        if(this.editingId){
            candidateDetails.id = this.editingId;
        }

        // console.log("candidateDetails", candidateDetails);

        const isValidResult = this.candidateIsValid(candidateDetails);
        if(isValidResult.resultIsValid){
            return $.ajax({
                type: (candidateDetails.id ? "PUT" : "POST"),
                url: API_HOST + "/candidate_data",
                data: candidateDetails,
                success: function(model, result, xhr){
                    $('#candidateModalContainer').modal('hide');

                    dc.clearModalFields();
                    
                    dc.mainGrid.jsGrid("loadData");
                },
                error: function(result, xhr){
                    $('#logModalContainer').modal('hide');
                    alert("There was an error entering the data.  If tihs issue continues, please contact the site administror at softwareengineeringsupport@geoengineers.com.")
                    dc.mainGrid.jsGrid("loadData");
                }
            });
        }else{
            console.log("isValidResult", isValidResult);
            alert(isValidResult.message);
        }

        return false;
    }

    clearModalFields() {
        $('#firstName').val("");
        $('#lastName').val("");  
        $('#position').val("");  
        // $('#address').val("");
        // $('#city').val("");
        // $('#state').val("");
        // $('#zip').val("");
        // $('#phone').val("");
        $('#email').val("");
        $('#details').val("");
        $('#referencesMinimum').val("");

        this.editingId = null;
        return false;
    }

    render() {
        return <div className="grid-default-dimensions">

            <div id="candidate-container" className="container-fluid" ref={this.gridRef}></div>

            <div className="container-fluid" style={{ textAlign:"right" }}>
                <button type="button" className="btn btn-primary" data-toggle="modal" onClick={this.showAddCandidate.bind(this)}> {/*  data-target="#candidateModalContainer" */}
                    Add new job candidate
                </button>
            </div>

            <canvas id="canvas"></canvas>

            <div className="modal" tabIndex="-1" id="detailsModalContainer"  role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body" id="detailsModalBody">
                            No content
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="cancelCandidate">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" tabIndex="-1" id="candidateModalContainer"  role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Enter Candidate Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-4">First Name:</div>
                                <div className="col-8"><input type="text" name="firstName" id="firstName" style={{ width:"250px" }} /></div>
                            </div>
                            <div className="row">
                                <div className="col-4">Last Name:</div>
                                <div className="col-8"><input type="text" name="lastName" id="lastName" style={{ width:"250px" }} /></div>
                            </div>
                            <div className="row">
                                <div className="col-4">Position Applied:</div>
                                <div className="col-8"><input type="text" name="position" id="position" style={{ width:"250px" }} /></div>
                            </div>

                            {/* <div className="row">
                                <div className="col-4">Phone:</div>
                                <div className="col-8"><input type="text" name="phone" id="phone" style={{ width:"250px" }} /></div>
                            </div> */}
                            <div className="row">
                                <div className="col-4">Email:</div>
                                <div className="col-8"><input type="text" name="email" id="email" style={{ width:"250px" }} /></div>
                            </div>
                            {/* <div className="row">
                                <div className="col-4">Address:</div>
                                <div className="col-8"><input type="text" name="address" id="address" style={{ width:"250px" }} /></div>
                            </div>
                            <div className="row">
                                <div className="col-4">City:</div>
                                <div className="col-8"><input type="text" name="city" id="city" style={{ width:"250px" }} /></div>
                            </div>
                            <div className="row">
                                <div className="col-4">State:</div>
                                <div className="col-8"><input type="text" name="state" id="state" style={{ width:"250px" }} /></div>
                            </div>

                            <div className="row">
                                <div className="col-4">Zip:</div>
                                <div className="col-8"><input type="text" name="zip" id="zip" style={{ width:"250px" }} /></div>
                            </div> */}
                            <div className="row">
                                <div className="col-4">Additional Details:</div>
                                <div className="col-8">
                                    <textarea type="text" name="details" id="details" style={{ width:"250px", height: "200px" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">Minimum required references:</div>
                                <div className="col-8"><input type="text" name="referencesMinimum" id="referencesMinimum" style={{ width:"250px" }} /></div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" id="saveCandidate" onClick={this.saveCandidate.bind(this)}>Save changes</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="cancelCandidate">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>;
    }
}  

export default Candidates;